import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.title}</h1>
    <Breadcrumbs text="Help" to="/help/" mdxType="Breadcrumbs" />
    <Aside mdxType="Aside">
      <h2>{`See Also`}</h2>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/what-to-expect/"
          }}>{`What to Expect`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/tactical-asset-allocation/"
          }}>{`What Is Tactical Asset Allocation?`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/backtesting/"
          }}>{`How We Backtest`}</a></li>
      </ul>
    </Aside>
    <p>{`Investing builds upon trust. And the same way we like to know our friends really well before loaning them money, we should understand our investments before committing to them. Throughout the site, we characterize our portfolios with the help of charts and metrics. This post explains which of these we use and how to interpret them.`}</p>
    <div id="investment-returns" />
    <h2>{`Investment Returns`}</h2>
    <p>{`The primary objective of investing is to make a profit. Consequently, most investors first wonder about their `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Return_on_investment"
      }}>{`return on investment`}</a>{`.`}</p>
    <p>{`In its purest form, we invest a dollar amount, wait patiently for our investment to grow, and get paid a larger amount later. Here is what this looks like in our metrics:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "876px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/bed9b59ac8c2890a2a4806b23951960e/1b1d5/metrics-abs-return.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "18%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAfUlEQVQI13WO2woCMRBD+/9/qmzF2sx1OzOyLIoK5ikEDjkNQP1PZs45e7/dx6iqxxiX63Xbtt47QA2AmUVEfiQiRNXMMlOYicjdjy6Cid13d19rNSJi5m82Yy0AopqZzGzup4WZschbqjGxqv7YRgTxuR9vZnbuqiovuKqemDLqqnbg4N8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "metric: absolute return",
            "title": "metric: absolute return",
            "src": "/static/bed9b59ac8c2890a2a4806b23951960e/1b1d5/metrics-abs-return.png",
            "srcSet": ["/static/bed9b59ac8c2890a2a4806b23951960e/5a46d/metrics-abs-return.png 300w", "/static/bed9b59ac8c2890a2a4806b23951960e/0a47e/metrics-abs-return.png 600w", "/static/bed9b59ac8c2890a2a4806b23951960e/1b1d5/metrics-abs-return.png 876w"],
            "sizes": "(max-width: 876px) 100vw, 876px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The example shows how our `}<a parentName="p" {...{
        "href": "/portfolios/tt-pick-me-up/"
      }}>{`Pick Me Up`}</a>{` strategy grew a hypothetical investment of $1,000 made in January 2007 to more than $4,500 in April 2020. To put this number in context, we use benchmarks. In this example, we use `}<em parentName="p">{`SPDR’s S&P 500 ETF`}</em>{` (SPY) as a reference, learning that `}<em parentName="p">{`Pick Me Up`}</em>{` outperformed the benchmark.`}</p>
    <div id="cagr" />
    <p>{`While these absolute numbers are very intuitive, they are not always easy to compare. To address this issue, we calculate the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Compound_annual_growth_rate"
      }}>{`Compound Annual Growth Rate`}</a>{`, often abbreviated as `}<em parentName="p">{`CAGR`}</em>{`. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "877px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8eeb0eba12533581ca6dbf1e281bf434/4b446/metrics-cagr.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "12.666666666666668%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAYElEQVQI132OQQoDIRAE/f9LJbouWZxxp1s7AXNMSFHXgkqv/0jX86qltvOUNMbIOZdHaUerR00EsY2IOae+iDvcB4AlTdLMsAMAycytm5v33kn+iAMk94TWWneEpM/WG8usr/FjSTNWAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "metric: relative return",
            "title": "metric: relative return",
            "src": "/static/8eeb0eba12533581ca6dbf1e281bf434/4b446/metrics-cagr.png",
            "srcSet": ["/static/8eeb0eba12533581ca6dbf1e281bf434/5a46d/metrics-cagr.png 300w", "/static/8eeb0eba12533581ca6dbf1e281bf434/0a47e/metrics-cagr.png 600w", "/static/8eeb0eba12533581ca6dbf1e281bf434/4b446/metrics-cagr.png 877w"],
            "sizes": "(max-width: 877px) 100vw, 877px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This number expresses the growth of our investment as an annual percentage. Our `}<em parentName="p">{`Pick Me Up`}</em>{` strategy grows by slightly more than 12% per year over the simulation period. This number assumes that all gains stay invested; they are continuously `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Compound_interest"
      }}>{`compounded`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "907px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/dfb57adf1bfa8a1445f5a5d274ea4ff8/142fb/chart-cagr.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABdklEQVQY02WR3yuDYRTHn9245D+Qv0SuXbqYDElESpLkTrlzv7t3XJAbNyRJGZv5kTTmx2wjbMyWwhjvnr3v3nOe5xx53xXl06nzo++3U+cIAFBKaRelNDOd563ci6RaLWU+Xpazlqx9WbbtAHsQMlrl7DPajpBSIqJy0UopRCJNzMBMdbVmtPnj/SORiayeBoPR4amN1vGtUsURjuP8eJgVs/YMpMmRDGY1cxtfT8wZ+xMzm/6pcMvIXkP/tm/0QIwdN07HSxYIIneBrHLliwp5TqUfoheR5ZPp2d3m4Yjwb/i6I2LwSAwdioEz0Z/29V2JrmTT4HWpogQCyGQ6FYtur8UOY3e7yfvJlWTbYqJ9KdWxdB1YfOydL/QahR6jEDCKgdBTd+ip08gPLBQrthZaa5ASiHfei+G33I31+qkkUJUY+Rf6E78TUc+gJJgmmkz8c3skRAREAEDwCvQANxCQyDUzs9aaNBFR/Wlug66Z/uFJmPkbVt+dNLQEDkAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chart: relative return",
            "title": "chart: relative return",
            "src": "/static/dfb57adf1bfa8a1445f5a5d274ea4ff8/142fb/chart-cagr.png",
            "srcSet": ["/static/dfb57adf1bfa8a1445f5a5d274ea4ff8/5a46d/chart-cagr.png 300w", "/static/dfb57adf1bfa8a1445f5a5d274ea4ff8/0a47e/chart-cagr.png 600w", "/static/dfb57adf1bfa8a1445f5a5d274ea4ff8/142fb/chart-cagr.png 907w"],
            "sizes": "(max-width: 907px) 100vw, 907px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`We can find the same information on the `}<em parentName="p">{`Equity Chart`}</em>{`. Horizontally, we see the time axis: starting in January 2007 and ending in April 2020. Then, vertically, we have the dollar value of our investment: starting at $1,000 and ending at $4,500.`}</p>
    <p>{`Please note that we scale the vertical axis `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Semi-log_plot"
      }}>{`logarithmically`}</a>{`. This type of scaling has several advantages over linear scaling:`}</p>
    <ul>
      <li parentName="ul">{`Equal growth factors, e.g., a doubling in value, result in the same vertical distance: the distance from $1,000 to $2,000 is the same as the distance from $2,000 to $4,000.`}</li>
      <li parentName="ul">{`The resulting chart shows a straight line if an investment grows at a constant rate.`}</li>
      <li parentName="ul">{`If two investments grow at the same rate, their equity curves are parallel.`}</li>
    </ul>
    <p>{`The `}<em parentName="p">{`CAGR`}</em>{` cannot be found directly on the chart. However, we find it represented by the `}<em parentName="p">{`slope`}</em>{` of the equity curve: the steeper the slope, the higher the growth rate.`}</p>
    <div id="annual-returns" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "906px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/40b936e4d9c55aa0c2f12eabb78b872b/6029f/chart-annual.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB2klEQVQoz4VSS2/TQBD2T+eCgBtJicTjVvXAgQuHIh5OaAQ0UoXgQEmVSFEexIntOLEdO97H7M6g3XUtSxz4ZK2+2dlvxppvPGoB7ZmeVMmVECLN8zTPOOdKKa1165l5iIgeItZXiNryaSRWCSMtmOSlqBhjVVVxIUCZbHwEJg0BAM+VdCU0Opha17Nqm4NN1Q2d+Nkg+TbbE5F0YtsST4ILkITEpSn31I+Gk8hqTDgNhT8+EtGZH/vjdS1WVo1EoIvXN9voqJjU56Ok2998nYZNw6tJ+eT93HTuJ/27zX1nNIULhh9/xw/ezoeTOMz1w8tl11+cXwf9u5AI3/w4dPrLl8MlEfUG92IJ3jZjOSuTwgg6nxYdf/FiuOt9Xp35y8fvFr3BnEi/+hI/upw9v1oSYdff+eOgFmcn+XNVfLiNDiUPUh7mPEirbcbCjG0zFuX8NmC7Qm5Stkn5aHZYp8X3xelitCYCjxCFwj+HHTonm+E2vreYtUaXAn8FIYDwlNJImBR7CWDmrrUZoYHl1jrH7WRrO5VWEqSHdtjxca+0coY3Xrf3p06R/UwJUsr5TBQeE6X+L6ZWbDbM7a0A6Ugb9ofr81/U66ksAMCR5oYxJqVswjYAQGv9F5KjHCvzc/jjAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chart: annual returns",
            "title": "chart: annual returns",
            "src": "/static/40b936e4d9c55aa0c2f12eabb78b872b/6029f/chart-annual.png",
            "srcSet": ["/static/40b936e4d9c55aa0c2f12eabb78b872b/5a46d/chart-annual.png 300w", "/static/40b936e4d9c55aa0c2f12eabb78b872b/0a47e/chart-annual.png 600w", "/static/40b936e4d9c55aa0c2f12eabb78b872b/6029f/chart-annual.png 906w"],
            "sizes": "(max-width: 906px) 100vw, 906px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Most investments fail to provide steady returns with a constant growth rate. Instead, their growth rate fluctuates, making it difficult to read from the chart. Here, a bar chart showing the `}<em parentName="p">{`annual returns`}</em>{` comes in handy. Horizontally, we see the calendar years, and vertically we plot the growth rate for the respective calendar year.`}</p>
    <p>{`While this is a familiar and handy representation, it has some shortcomings. For once, it does not show what happened between the year's beginning and end: this path could have been all but smooth. Also, any gains or losses close to the end of the year induce some misleading jitters here.`}</p>
    <div id="rolling-returns" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3dfafe3d8ec8cca0cb18ba5fe022cd9f/21b4d/chart-rolling-returns.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABb0lEQVQoz4WRP2tUQRTF3+fIB7D0U1jYB0kCQTGFGEI+gF0a+1hJiBESlhSyXSwCNoJgIUkUwpoi7pvdF99G2HX37fszMztvZn7yhg1JKg8czjlz752BO1ElFWVZUVWSmakBh/eO8STj8lcXIQSD9Dex6NGNBWmaovQMvA00pg5zShtMbYmGkynZeEQtpzT4Ek8olOUuZvdjwCivA8GH/OlizOUgI2oOPp5L2qcFx52caP2Mh68v2Ps6pP39L89bMQ+2Oqy1erRPszDcOhkSvfzBo+0uDsv252sWXnX4mYyaCx1H5xOWd/tsHKYsvRMs7ghW3sc8PRAs7fZ5spPw+I1g80Ofb1cZLw4TFt8mPNvvsX/yh9WDLqt7VySDEZEJe2tg8Bj+B+Pcvez9rVdKEmmtcc5TGU1hJHVtw3LrOYO3bq4W71zQmx7nHM3HTvMCYwyRlBKl1Jw6aPPIXZ/n+W2P1uh57aZeliVFWWKt4x/rWgHAq3DQdwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chart: portfolio rolling returns",
            "title": "chart: portfolio rolling returns",
            "src": "/static/3dfafe3d8ec8cca0cb18ba5fe022cd9f/c1b63/chart-rolling-returns.png",
            "srcSet": ["/static/3dfafe3d8ec8cca0cb18ba5fe022cd9f/5a46d/chart-rolling-returns.png 300w", "/static/3dfafe3d8ec8cca0cb18ba5fe022cd9f/0a47e/chart-rolling-returns.png 600w", "/static/3dfafe3d8ec8cca0cb18ba5fe022cd9f/c1b63/chart-rolling-returns.png 1200w", "/static/3dfafe3d8ec8cca0cb18ba5fe022cd9f/21b4d/chart-rolling-returns.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Calculating rolling 12-months returns addresses this issue. Instead of discrete bars, we now have a smooth line representing our returns. This view makes it easy to spot periods of outsized returns or underperformance. Also, the raggedness of the curve provides an intuitive impression of the portfolio's volatility.`}</p>
    <div id="tracking-to-benchmark" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3bc27240d837fd9be895f5a784ecf016/21b4d/chart-tracking-to-benchmark.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA6UlEQVQoz4WS22rEMAxE8///GJzN6xZCHF9lSVMkp922sNRwUCyNHDH2EuOFlBKICGMMMDNSTti2Deu6Yt93PPYHQtgQQsB5nq5xresn1q+qWJ7PDxzH4SIRcazwfin01g0WNGKchXGm5rnlfZ9OMBFRDH7F0gX5hgU+YboSFpvm50T2PUTBoqChqCSOHeDce/numXEQuR0+oSj8T5X0F2QTKfzw/5Z5GmPEUjsjFkInBg12L82j6dPLV8v/xX0cAylnXNc1LyWmglIKai1oraLWitYaSpnRXoCJe+8od800X1jO6jlnv5RP9HQlHNKC46cAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chart: portfolio tracking to benchmark",
            "title": "chart: portfolio tracking to benchmark",
            "src": "/static/3bc27240d837fd9be895f5a784ecf016/c1b63/chart-tracking-to-benchmark.png",
            "srcSet": ["/static/3bc27240d837fd9be895f5a784ecf016/5a46d/chart-tracking-to-benchmark.png 300w", "/static/3bc27240d837fd9be895f5a784ecf016/0a47e/chart-tracking-to-benchmark.png 600w", "/static/3bc27240d837fd9be895f5a784ecf016/c1b63/chart-tracking-to-benchmark.png 1200w", "/static/3bc27240d837fd9be895f5a784ecf016/21b4d/chart-tracking-to-benchmark.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`However, comparing portfolios remains difficult. This is where the tracking chart comes in. We create it by dividing the equity curve of our portfolio by the benchmark's curve. As a result, we can see how much better or worse than the benchmark a portfolio performed. If they perform the same, the line runs horizontally. If the portfolio beats the benchmark, the line slopes upwards.`}</p>
    <div id="investment-risks" /><div id="stdev" />
    <h2>{`Investment Risks`}</h2>
    <p>{`Losing money is a painful experience. Therefore, many of the charts and metrics aim to measure investment risks.`}</p>
    <p>{`As we have seen above, returns are not constant but fluctuate over time. A measure for this fluctuation is the `}<em parentName="p">{`Standard Deviation of Returns`}</em>{` or `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Volatility_(finance)"
      }}>{`Volatility`}</a>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "876px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4466de06093d29bd434e7a7135080d05/1b1d5/metrics-stdev.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "12.333333333333332%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAASUlEQVQI13XMSQrAMAxD0dz/sE7BsjMg26UpXfbBX0oN0Dln/cgIVe3S995Bish1jDNpay0D7AnmDoBkfiJyDM+MqiJp7vmeHjfmRHUworXtiwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "metric: standard deviation of returns",
            "title": "metric: standard deviation of returns",
            "src": "/static/4466de06093d29bd434e7a7135080d05/1b1d5/metrics-stdev.png",
            "srcSet": ["/static/4466de06093d29bd434e7a7135080d05/5a46d/metrics-stdev.png 300w", "/static/4466de06093d29bd434e7a7135080d05/0a47e/metrics-stdev.png 600w", "/static/4466de06093d29bd434e7a7135080d05/1b1d5/metrics-stdev.png 876w"],
            "sizes": "(max-width: 876px) 100vw, 876px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This number helps us estimate a `}<em parentName="p">{`range`}</em>{` of likely investment returns. `}<em parentName="p">{`Our Pick Me`}</em>{` Up strategy has a `}<em parentName="p">{`Compound Annual Growth Rate`}</em>{` of about 12%, and its `}<em parentName="p">{`Standard Deviation of Returns`}</em>{` is almost 16%. Statistically speaking, we have a likelihood of 68% that `}<em parentName="p">{`Pick Me Up`}</em>{` will return between -4% (12% minus 16%) and +28% (12% plus 16%) annually. The bar chart above confirms that our `}<em parentName="p">{`Pick Me Up`}</em>{` returns stay close to these bounds while there are, of course, no guarantees.`}</p>
    <p>{`We like to point out that the `}<em parentName="p">{`Standard Deviation of Returns`}</em>{` is higher than the `}<em parentName="p">{`Compound Annual Growth Rate`}</em>{` for many strategies. In other words, volatility drowns the trend, and there is a substantial risk of losing money over a given 1-year period.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "907px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e04aaaf43fd3f62ff7844990188c92f5/142fb/chart-stdev.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABk0lEQVQY001Ry0pbURQ9fkH6B04KBb/DUR0VOihGQxAEhSJ0IAWh+g+Ko3BNaUvxUVCH1visVoOKIldzk5ikhqDxEZI0Jzc3Z7/kXqt0sfdmsVh7sNdWxhhEpACIJMLHl26+3GDXtZvFE33peaDBeABiWoKu3N1ANne243jNttJaAwAiIgAxk/hgERBhYwRbXK9gOgvpiz+7dnxqY2Q80Tu23h2Zr9Zc1W63H3cocFO9zrUq57KSz5SPUt9iO6OffkYnNsfGl3veL3ZFF1+FFzoH5l5+TFS0p5hZdIMLBXEczmTEdsqJ5OFqyppefz08/+Lt91DPj9CbORVZUu8SKnzQEXVUOB0azN5rVAiQ3t74tbVyvLZ/vZnMHSW/LKx+mJwdiq0Nxg+GrVTf56ve+G3/TLnPuo5YpQGr2B8rDn0tNTxSRKQvck37/Le9t1s6LVTyVXBdQfYPl6cEKJiP9SyyCogv/eVWjZoiQoAMhAAGjDEABv1h/FgBAg5+Mz8tEwAjMfO/p5HPfY8xzPys0H8QkQcqxplK5IwIfAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chart: standard deviation of returns",
            "title": "chart: standard deviation of returns",
            "src": "/static/e04aaaf43fd3f62ff7844990188c92f5/142fb/chart-stdev.png",
            "srcSet": ["/static/e04aaaf43fd3f62ff7844990188c92f5/5a46d/chart-stdev.png 300w", "/static/e04aaaf43fd3f62ff7844990188c92f5/0a47e/chart-stdev.png 600w", "/static/e04aaaf43fd3f62ff7844990188c92f5/142fb/chart-stdev.png 907w"],
            "sizes": "(max-width: 907px) 100vw, 907px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`We can also find the same measure on our `}<em parentName="p">{`Equity Chart`}</em>{`. If `}<em parentName="p">{`CAGR`}</em>{` is the `}<em parentName="p">{`slope`}</em>{`, then the `}<em parentName="p">{`Standard Deviation of Returns`}</em>{` is the `}<em parentName="p">{`width`}</em>{` of a corridor within which the equity curve ranges most of the time.`}</p>
    <div id="mdd" />
    <p>{`From a mathematical perspective, these may be useful measures, but many investors think differently: Investments are supposed to grow, and any reversal from that upward trajectory is considered a loss. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "907px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/275a048e3eba9d37a1f2bfcb05791de3/142fb/chart-dd.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABVUlEQVQY022Q2UrDUBCG83I+jq/gnSuCQuu16I0K3li8kHpnIy6NS7FSaxpPTtLU1Lq2SZucM4umJbjgx8D8/MwMM2MwMxMx4jgTM75EOEyJtXocdDv9EEZJVvAfBjObLXt9fquwam4slna3L2YPX+f2vc2qvVa/KNarpUareH69XKmbQWCF4YJ5Wzhx9pqyHysDkDXzzNmlMX0+teTXfDzotVfsK+ttkCAnTDFzYxAfBD1mjhB2RFAOerXn0VOkjGzVTjfpNLfKpmXaQh6HTu2meaQ8wZ5HUrKU7Pns++RKlB622+zL0G68Rx8GE+l2oB05lI7Tsu4ap/GDQzJQwtWuhHFo4SrhTkQqXBCyL2zQKrsZOftHJb4/SR6yt+Xxkz8mMSutx80ADNhNXoPRMyMBaAJAAAIgRBxrzJ1vTWRMJimlCBA1aK0hRymVpin8Rud8NX8CcLO5PjSCDFkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chart: maximum drawdowns",
            "title": "chart: maximum drawdowns",
            "src": "/static/275a048e3eba9d37a1f2bfcb05791de3/142fb/chart-dd.png",
            "srcSet": ["/static/275a048e3eba9d37a1f2bfcb05791de3/5a46d/chart-dd.png 300w", "/static/275a048e3eba9d37a1f2bfcb05791de3/0a47e/chart-dd.png 600w", "/static/275a048e3eba9d37a1f2bfcb05791de3/142fb/chart-dd.png 907w"],
            "sizes": "(max-width: 907px) 100vw, 907px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><em parentName="p">{`Underwater Charts`}</em>{` reflect this view: they chart the percentage lost since a previous all-time high over time, the so-called `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Drawdown_(economics)"
      }}>{`drawdown`}</a>{`. From the chart above, we learn that our `}<em parentName="p">{`Pick Me Up`}</em>{` portfolio suffered its worst loss in late 2008, about 25% from its previous high. Further, we can see that the longest drawdown started in October 2007 and lasted until January 2010.`}</p>
    <p>{`Our metrics show this information as follows:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "876px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4e18a0053070e3189bd109ef5761c140/1b1d5/metrics-dd.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "18%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAbElEQVQI13WOwQ7DIAxD+f8/XUWkMYJdEhaqrhKXiXdzLCsvqercExG1VsnyLmXO+SnldRw5ZxFRbUlV+4/YAPAkzT0ieBLA8GHdxhgJQEMDudl+Abj7Y2FmvfcllQCCXKd/cRKrdXfifvPEC8In6ytHUKDcAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "metric: drawdowns",
            "title": "metric: drawdowns",
            "src": "/static/4e18a0053070e3189bd109ef5761c140/1b1d5/metrics-dd.png",
            "srcSet": ["/static/4e18a0053070e3189bd109ef5761c140/5a46d/metrics-dd.png 300w", "/static/4e18a0053070e3189bd109ef5761c140/0a47e/metrics-dd.png 600w", "/static/4e18a0053070e3189bd109ef5761c140/1b1d5/metrics-dd.png 876w"],
            "sizes": "(max-width: 876px) 100vw, 876px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <div id="maximum-flat-days" />
    <p>{`In addition to the `}<em parentName="p">{`Maximum Drawdown`}</em>{`, we also measure the `}<em parentName="p">{`Maximum Flat Days`}</em>{`. This number is the longest time it took from one peak to making a new all-time high. With 805 days, this is more than two years. And even though that is a long time, this is still less than half of the benchmark.`}</p>
    <p>{`We calculate the `}<em parentName="p">{`Maximum Drawdown`}</em>{` at the end of each day. This leads to worse results than calculating drawdowns only at the end of the month.`}</p>
    <div id="ulcer-index" />
    <p>{`Another way to quantify risk is the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Ulcer_index"
      }}>{`Ulcer Index`}</a>{`. This index is the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Root_mean_square"
      }}>{`Root Mean Square`}</a>{` of daily drawdowns. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "877px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5fb0293814287979cb30cb4f0b045d21/4b446/metrics-ulcer.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "12.333333333333332%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAARUlEQVQI13XMwRHAIAgEQPsvVnEEMQx35OE32QK2qeo5p36Q1LXGGBGBzN67zCki7l5VLeIxMwD8AmDvfaPMdHeSNyX5Av7Gda8URC7yAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "metric: ulcer index",
            "title": "metric: ulcer index",
            "src": "/static/5fb0293814287979cb30cb4f0b045d21/4b446/metrics-ulcer.png",
            "srcSet": ["/static/5fb0293814287979cb30cb4f0b045d21/5a46d/metrics-ulcer.png 300w", "/static/5fb0293814287979cb30cb4f0b045d21/0a47e/metrics-ulcer.png 600w", "/static/5fb0293814287979cb30cb4f0b045d21/4b446/metrics-ulcer.png 877w"],
            "sizes": "(max-width: 877px) 100vw, 877px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Because the index uses squared drawdowns, it applies larger weights to more profound losses. Therefore, we can interpret the `}<em parentName="p">{`Ulcer Index`}</em>{` as a `}<em parentName="p">{`representative drawdown`}</em>{`, which our investment will reach `}<em parentName="p">{`frequently`}</em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "907px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5928873e2bbdb6b8902b333fb3e31944/142fb/chart-ulcer.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.666666666666664%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABO0lEQVQY03WQ20oCURSG50F6sm56jl5CCqwQoggvhJAImfDGkBEPTDqa5rE8UWoEluKo4957HWpmtAzqY7P5/4t/8a+l8Rbk/WOHF8p1r2IyEh+gkP9BYyKjWApECgcXjeNINao3A+GHw/D9Zdw8SsSDt3osmQndJE5iKcOyCuVKSM+e63d62prPVxoAqF4/eB3b2Yvu7mefzW7KNE4TV3WrvGr1nVZv/tjulKq5TB46Xbv1lDTMXCZfM2vvM0cjr67Dy7PGIPciKtwd0LQqBorgz6roLTcU9kQsNNcjMJCtlh1nWLTbtlwyMQIQ4vdDAAYgAFcgTaWtCLwwuSdJz+rZWdPtQYT0w/qWvva8X1ZK5YeJkd/EZCjGTAzoZhHXE3Bjt4WvNX+wlJIAUYFSCjZIKYUQ8Bu14Sv8CYhBvGK7AK24AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chart: ulcer index",
            "title": "chart: ulcer index",
            "src": "/static/5928873e2bbdb6b8902b333fb3e31944/142fb/chart-ulcer.png",
            "srcSet": ["/static/5928873e2bbdb6b8902b333fb3e31944/5a46d/chart-ulcer.png 300w", "/static/5928873e2bbdb6b8902b333fb3e31944/0a47e/chart-ulcer.png 600w", "/static/5928873e2bbdb6b8902b333fb3e31944/142fb/chart-ulcer.png 907w"],
            "sizes": "(max-width: 907px) 100vw, 907px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The chart above shows the `}<em parentName="p">{`Ulcer Index`}</em>{` for our `}<em parentName="p">{`Pick Me Up`}</em>{` strategy. Again, we see that the portfolio regularly reaches or exceeds this drawdown level.`}</p>
    <div id="risk_adjusted_returns" /><div id="sharpe-ratio" /><div id="martin-ratio" />
    <h2>{`Risk-Adjusted Returns`}</h2>
    <p>{`When working a regular job, we get paid for performing our duty. Similarly, as investors, we get paid for taking on risk, the so-called `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Risk_premium"
      }}>{`risk premium`}</a>{`. By dividing a return measure by a risk measure, we calculate `}<em parentName="p">{`Risk-Adjusted Returns`}</em>{`. In a nutshell, these measures express how well we get paid for taking on risks.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "876px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4e1f669fb8b984e8546b74a049620806/1b1d5/metrics-risk-adjusted.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "18%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAdUlEQVQI13WO0QpDIQxD/f8/FRTmtWoTrY4rm2yDnaeT0EKciKz/zDmvK4cQHymttVJK3vsQQoxRpLicM6CqANCamtn8RhUESW6/D0fvBMcYrmxqraUUESG5Pj7N7G7eKwCa2YmublprL1H9Wd57P07SbJ74BIlq6sIGV04OAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "metric: risk-adjusted returns",
            "title": "metric: risk-adjusted returns",
            "src": "/static/4e1f669fb8b984e8546b74a049620806/1b1d5/metrics-risk-adjusted.png",
            "srcSet": ["/static/4e1f669fb8b984e8546b74a049620806/5a46d/metrics-risk-adjusted.png 300w", "/static/4e1f669fb8b984e8546b74a049620806/0a47e/metrics-risk-adjusted.png 600w", "/static/4e1f669fb8b984e8546b74a049620806/1b1d5/metrics-risk-adjusted.png 876w"],
            "sizes": "(max-width: 876px) 100vw, 876px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The most commonly used measure for risk-adjusted return is the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Sharpe_ratio"
      }}>{`Sharpe Ratio`}</a>{`. At its core, it divides the `}<em parentName="p">{`Compound Annual Growth Rate`}</em>{` by the `}<em parentName="p">{`Standard Deviation of Returns`}</em>{`. However, there is an additional twist: we subtract the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Risk-free_interest_rate"
      }}>{`Risk-Free Rate of Return`}</a>{` from the profits before performing these calculations. Doing so makes sense, as we are looking for a measure of taking on risk, and receiving risk-free interest is not that. In today's environment of low-interest rates, the risk-free rate is close to zero. However, this has not been the case in prior decades.`}</p>
    <p>{`There are many ways to calculate the `}<em parentName="p">{`Sharpe Ratio`}</em>{`, making this measure hard to compare. For `}<em parentName="p">{`TuringTrader.com`}</em>{`, we chose to use monthly returns and annualize the results. For the risk-free rate, we use the `}<a parentName="p" {...{
        "href": "https://fred.stlouisfed.org/series/DTB3"
      }}>{`Secondary Market Rate for the 3-Month Treasury Bill`}</a>{`.`}</p>
    <p>{`Another important measure for risk-adjusted return is the `}<em parentName="p">{`Ulcer Performance Index`}</em>{` or `}<em parentName="p">{`Martin Ratio`}</em>{`. This measure divides the `}<em parentName="p">{`CAGR`}</em>{` by the `}<em parentName="p">{`Ulcer Index`}</em>{`.`}</p>
    <p>{`Our example shows how, according to the `}<em parentName="p">{`Sharpe Ratio`}</em>{`, the `}<em parentName="p">{`Pick Me Up`}</em>{` portfolio produces more than twice the return per unit of risk as a straight investment in the `}<em parentName="p">{`S&P 500`}</em>{` would. According to the `}<em parentName="p">{`Ulcer Performance Index`}</em>{`, our `}<em parentName="p">{`Pick Me Up`}</em>{` strategy achieves almost four times the risk-adjusted return of the S&P 500. When comparing portfolios, it is good practice to watch both measures.`}</p>
    <p>{`Investors often believe that to achieve high returns, they have to take on high levels of risk. Risk-adjusted returns debunk that myth. Instead, investments with similar risk profiles may lead to very different profits, and `}<em parentName="p">{`Tactical Asset Allocation`}</em>{` augments this effect.`}</p>
    <div id="beta" />
    <h2>{`Beta`}</h2>
    <p>{`Mathematically speaking, `}<em parentName="p">{`Beta`}</em>{` is a measure of correlation. It is part of the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Capital_asset_pricing_model"
      }}>{`Capital Asset Pricing Model`}</a>{` and describes how much an investment moves in tandem with the market. `}<em parentName="p">{`Beta`}</em>{` may seem desirable in bullish markets, as stocks with a `}<em parentName="p">{`Beta`}</em>{` larger than one will provide amplified growth. In the context of portfolios, `}<em parentName="p">{`Beta`}</em>{` is typically seen as a risk factor, though.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "876px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/605fdb1dcba6a36cd77a6e3b7c07cff6/1b1d5/metrics-beta.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "12.333333333333332%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAARElEQVQI13XMQQqAQAxD0bn/YTtCC4Y2bdVRwY1vGfgZZgqgf1Smqk6Z7p6kiGzLvpIBwF7ufgUfJO+xu0lGxHNadcYH76F1e0kNJRYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "metric: portfolio beta",
            "title": "metric: portfolio beta",
            "src": "/static/605fdb1dcba6a36cd77a6e3b7c07cff6/1b1d5/metrics-beta.png",
            "srcSet": ["/static/605fdb1dcba6a36cd77a6e3b7c07cff6/5a46d/metrics-beta.png 300w", "/static/605fdb1dcba6a36cd77a6e3b7c07cff6/0a47e/metrics-beta.png 600w", "/static/605fdb1dcba6a36cd77a6e3b7c07cff6/1b1d5/metrics-beta.png 876w"],
            "sizes": "(max-width: 876px) 100vw, 876px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This makes sense, as a well-rounded portfolio should appreciate value throughout all economic seasons and independently from a single asset class's movements. On our site, we always measure `}<em parentName="p">{`Beta`}</em>{` against the S&P 500, as stocks are the driver of growth in most portfolios and the most significant contributor to portfolio risk.`}</p>
    <div id="monte-carlo-simulations" />
    <h2>{`Monte-Carlo Simulations`}</h2>
    <p>{`Simulations of our strategies with historical data, so-called `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Backtesting"
      }}>{`Backtests`}</a>{`, are useful methods to analyze how the portfolios performed under past market conditions. However, even though markets go through cycles, history does not repeat itself exactly. `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Monte_Carlo_method"
      }}>{`Monte-Carlo Simulations`}</a>{` address this issue by analyzing the range of possible outcomes based on given confidence intervals.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/18e2f0b671660f19cf762ff37b652b66/eea4a/chart-monte-carlo-cagr.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAHABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAME/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAd1iiwl//8QAGBAAAwEBAAAAAAAAAAAAAAAAAQIDABH/2gAIAQEAAQUCSdwoSuUNz//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AYf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEv/aAAgBAgEBPwGj/8QAGxAAAQQDAAAAAAAAAAAAAAAAAAEDESEzkqH/2gAIAQEABj8Ct3kmVNS1k//EABoQAQABBQAAAAAAAAAAAAAAAAERABAxUXH/2gAIAQEAAT8hOAcbAIMtuIr/2gAMAwEAAgADAAAAEHPP/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8QAT//xAAXEQEBAQEAAAAAAAAAAAAAAAABIRCR/9oACAECAQE/EFNnDP/EABsQAAIDAAMAAAAAAAAAAAAAAAERACGBMbHR/9oACAEBAAE/EAP6AqENMAtuPYqjfw6nP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chart: monte-carlo simulation of CAGR",
            "title": "chart: monte-carlo simulation of CAGR",
            "src": "/static/18e2f0b671660f19cf762ff37b652b66/e5166/chart-monte-carlo-cagr.jpg",
            "srcSet": ["/static/18e2f0b671660f19cf762ff37b652b66/f93b5/chart-monte-carlo-cagr.jpg 300w", "/static/18e2f0b671660f19cf762ff37b652b66/b4294/chart-monte-carlo-cagr.jpg 600w", "/static/18e2f0b671660f19cf762ff37b652b66/e5166/chart-monte-carlo-cagr.jpg 1200w", "/static/18e2f0b671660f19cf762ff37b652b66/eea4a/chart-monte-carlo-cagr.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`To evaluate a strategy's likely range of returns, we first calculate the distribution of monthly returns. Then, we run several thousand trials to create a set of equity curves, each representing an alternate reality based on the given distribution of returns. Next, we calculate an envelope of two equity curves, one representing the 5`}<sup>{`th`}</sup>{` percentile and the other the 95`}<sup>{`th`}</sup>{` percentile. Finally, we plot how the `}<em parentName="p">{`CAGR`}</em>{` changes over time.`}</p>
    <p>{`To read this chart, start on the horizontal axis, at the point representing your investment period. Next, read the upper and lower values for the CAGR on the vertical axis. The example above shows how, with a probability of 90%, `}<em parentName="p">{`All-Stars Total Return`}</em>{` CAGR will fall between 8.5% and 19% after ten years. Also, the chart shows how, with a probability of 95%, the portfolio's return will be positive after about 1.5 years. In comparison, an investment in a 60/40 portfolio will fall between 2% and 13% in the same period.`}</p>
    <p>{`These charts are valuable tools for determining suitability for an investor's personal circumstances, as they show the relationship between portfolio volatility and the investment horizon. To learn more about using these charts for financial planning, read our `}<a parentName="p" {...{
        "href": "/articles/portfolio-wizard/"
      }}>{`background article explaining our portfolio wizard`}</a>{`.`}</p>
    <p>{` `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3a6d596afad32817f1c4c6c675547f5f/eea4a/chart-monte-carlo-mdd.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdu4QD//xAAWEAEBAQAAAAAAAAAAAAAAAAACARD/2gAIAQEAAQUCAgmf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFhAAAwAAAAAAAAAAAAAAAAAAARAR/9oACAEBAAY/AoH/AP/EABgQAAIDAAAAAAAAAAAAAAAAAAERABAx/9oACAEBAAE/IcDhukZ//9oADAMBAAIAAwAAABBzz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABYRAQEBAAAAAAAAAAAAAAAAAAEhEP/aAAgBAgEBPxBtc//EABoQAQEAAgMAAAAAAAAAAAAAAAERADEhQaH/2gAIAQEAAT8Q5U1bVq1wF7fMttmf/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chart: monte-carlo simulation of maximum drawdown",
            "title": "chart: monte-carlo simulation of maximum drawdown",
            "src": "/static/3a6d596afad32817f1c4c6c675547f5f/e5166/chart-monte-carlo-mdd.jpg",
            "srcSet": ["/static/3a6d596afad32817f1c4c6c675547f5f/f93b5/chart-monte-carlo-mdd.jpg 300w", "/static/3a6d596afad32817f1c4c6c675547f5f/b4294/chart-monte-carlo-mdd.jpg 600w", "/static/3a6d596afad32817f1c4c6c675547f5f/e5166/chart-monte-carlo-mdd.jpg 1200w", "/static/3a6d596afad32817f1c4c6c675547f5f/eea4a/chart-monte-carlo-mdd.jpg 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`For `}<em parentName="p">{`Drawdowns`}</em>{`, the charts show how deep drawdowns might look like. Again, the process starts with creating a swarm of equity curves. However, this time, we analyze all drawdowns, regardless of how short-lived or insignificant they might be. Next, of these drawdowns, we select those 5% that go the deepest. Finally, we create an underwater curve at the 5`}<sup>{`th`}</sup>{` percentile of these deepest drawdowns. This chart represents the depth and duration we expect to see under prolonged bearish conditions, e.g., during a recession.`}</p>
    <p>{`To read the chart, pick a point on the underwater curve and extend it to the horizontal or vertical axis. The example above shows that `}<em parentName="p">{`All-Stars Total Return`}</em>{` might lose about 15% during a recession and might take about three years to recover from those losses. In comparison, a `}<em parentName="p">{`60/40 portfolio`}</em>{` might lose about 26% and take up to 9.5 years to recover.`}</p>
    <p>{`It is worth noting that these charts do not attempt to show the maximum possible drawdown. Because the probability of reaching the maximum drawdown is zero, it is technically impossible to determine that number using Monte-Carlo methods. Instead, our number should give a reasonable and realistic guideline of losses during a recession - backed with 95% confidence.`}</p>
    <div id="benchmarks" />
    <h2>{`Benchmarks`}</h2>
    <p><em parentName="p">{`Benchmarks`}</em>{` help to analyze strategies by putting the results in context. There is no set rule on which benchmark to use. Generally, we use benchmarks with a `}<em parentName="p">{`similar risk level`}</em>{` as our investment but not necessarily the same asset classes.`}</p>
    <p>{`We typically use a proxy for the overall stock market for aggressive stock growth strategies. Specifically, we use S&P 500 ETFs, which capture capital gains and dividends paid. This benchmark reflects that typical growth portfolios build on stocks, and we expect growth strategies to have a positive `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Correlation_and_dependence"
      }}>{`correlation`}</a>{` to the stock market. Further, the benchmark allows a direct comparison of the strategy's ability to handle `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Recession"
      }}>{`recession periods`}</a>{`.`}</p>
    <p>{`For less aggressive or cross-asset strategies, we use a `}<a parentName="p" {...{
        "href": "/portfolios/classic-60-40/"
      }}>{`Vanilla 60/40`}</a>{` portfolio. This benchmark reflects the goal of reducing portfolio volatility and is relevant because stock/bond portfolios are the ubiquitous approach to portfolio construction.`}</p>
    <div id="portfolio-comparison" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1090px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b5bf178cad38ccb9245daf3cd1ed4610/525d3/chart-return-risk.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABRElEQVQoz52T4W7bMAyE/f5POfTHgHZYY0eySEkkv0FynCZYAwwjQEigzkfqTl7cnYh4Sg+nWWeclVLYtg1VZcR3+MdcHgHn3txZa0ZEZu2M8/zV+kQ4pjr3jIS/Gv3LOgnnFAcH2YQsO2H+f4TB8dHaM6lkeu9Ua1xrpmp9qRnEtw2XU583+eD98xe9tgElVaXVNhs8SuAR9G6YGe52rx8RLOqVXQthRu3OZ1LWVFi3zL7vx8TNuGbFh/MBWRpVlS0VLldBtVJE2bKwdDfMjZhaBpdUuawJvTk8CFJpiOicLMI5Be/ms9mYdmTrxjKuMyYb4AH88Z54+/mBlP3LdcAc1qzY7X0eZ/6k5Xw2o6jN6HZ0n+Anh2NeW7Shtd8JBs5uxIeefnP55ti9E0GpghRBupL2xLZd+X1ZpwxFO6XIyz/lD/fgY5pP1fwTAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chart: portfolio comparison",
            "title": "chart: portfolio comparison",
            "src": "/static/b5bf178cad38ccb9245daf3cd1ed4610/525d3/chart-return-risk.png",
            "srcSet": ["/static/b5bf178cad38ccb9245daf3cd1ed4610/5a46d/chart-return-risk.png 300w", "/static/b5bf178cad38ccb9245daf3cd1ed4610/0a47e/chart-return-risk.png 600w", "/static/b5bf178cad38ccb9245daf3cd1ed4610/525d3/chart-return-risk.png 1090w"],
            "sizes": "(max-width: 1090px) 100vw, 1090px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Portfolio Comparison`}</h2>
    <p>{`Before selecting a portfolio, reviewing and understanding many (if not all) of the metrics we provide for our portfolios is a good idea. But sometimes, we need a quicker way to compare the characteristics of portfolios.`}</p>
    <p>{`The risk-adjusted metrics above fit this bill as they relate the historical returns with the risk taken to achieve them. However, when selecting portfolios, it is helpful to consider returns and risks separately. Our portfolio-comparison chart does just that: On the horizontal axis, it shows the portfolio risk. Portfolios with lower risks are to the left, and portfolios with higher risks are to the right. On the vertical axis, the chart shows the portfolio returns. Higher returns are on the top, and lower returns are on the bottom. Because returns and risks are somewhat related, there is a natural tendency for portfolios with higher returns also to have higher risks.`}</p>
    <p>{`To use the chart, pick an acceptable level of risk, e.g., an Ulcer Index of 3%. All portfolios matching this profile are on the left of this line. Among these portfolios, the ones with the highest returns are likely to be good choices, provided their other characteristics, e.g., investment style and rebalancing frequency, match your requirements.`}</p>
    <h2>{`Wrap-Up`}</h2>
    <p><em parentName="p">{`TuringTrader.com`}</em>{` offers a steadily growing selection of portfolios and strategies, along with background information, references, and daily updated charts and metrics. We very much hope that this guide helps you digest this wealth of information.`}</p>
    <ButtonPrimary text="explore our portfolios" to="/portfolios/" mdxType="ButtonPrimary" />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      